import React from "react";
import Section from "../../common/components/Section";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import Grid from "../../common/components/Grid";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";

const BENEFITS = [
  {
    id: 1,
    title: "매일의 선물 기록하기",
    description: "오늘 주고 받은 선물을 기록하세요!",
  },
  {
    id: 2,
    title: "알뜰 소비하기",
    description: "친구에게 맞는 추천 선물로 알뜰하게 구매해요",
  },
  {
    id: 3,
    title: "내 인맥, 지인 되짚기",
    description: "지인에게 받은 관심과 선물을 리마인드 해 드려요",
  },
];

function LandingBenefits() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor={theme.color.primary2}
      marginTop={isMobile ? 0 : -50}
    >
      <Flex
        position="relative"
        width="100%"
        backgroundColor={theme.color.black}
        flexDirection="column"
        zIndex={200}
        borderTopLeftRadius={isMobile ? 100 : 200}
        borderTopRightRadius={isMobile ? 0 : 200}
        padding={`${theme.spacing[isMobile ? 24 : 40]}px ${
          theme.spacing[24]
        }px`}
        gap={theme.spacing[32]}
      >
        <Flex
          width="100%"
          flexDirection={isMobile ? "column-reverse" : "column"}
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[8]}
        >
          <Text typography="16" color={theme.color.white}>
            똑똑하게 내 인맥 관리해요!
          </Text>
          <Text typography={isMobile ? "24B" : "40B"} color={theme.color.white}>
            어떻게 활용해야 하나요?
          </Text>
        </Flex>

        <Grid
          width="100%"
          gridTemplateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"}
          gap={theme.spacing[isMobile ? 16 : 8]}
        >
          {BENEFITS.map((benefit) => {
            const { id, title, description } = benefit;

            return (
              <Flex
                key={id}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={theme.spacing[12]}
              >
                <Flex
                  width={isMobile ? 32 : 46}
                  height={isMobile ? 32 : 46}
                  borderRadius={isMobile ? 32 / 2 : 23}
                  backgroundColor={theme.color.primary}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    typography={isMobile ? "20B" : "24B"}
                    textAlign="center"
                  >
                    {id}
                  </Text>
                </Flex>
                <Text
                  typography={isMobile ? "20B" : "24B"}
                  color={theme.color.primary}
                >
                  {title}
                </Text>
                <Text typography="16" color={theme.color.white}>
                  {description}
                </Text>
              </Flex>
            );
          })}
        </Grid>
      </Flex>
    </Flex>
  );
}

export default LandingBenefits;
