import React from "react";
import Layout from "../../common/components/Layout";
import LandingIntro from "../components/LandingIntro";
import LandingBenefits from "../components/LandingBenefits";
import LandingPosts from "../components/LandingPosts";
import { Helmet } from "react-helmet-async";

function LandingContainer() {
  return (
    <Layout>
      <LandingIntro />
      <LandingBenefits />
      <LandingPosts />
    </Layout>
  );
}

export default LandingContainer;
