import React, { useState } from "react";
import Section from "../../common/components/Section";
import { useInView } from "react-intersection-observer";
import {
  useGetActiveAdvertiseQuery,
  useGetPostsQuery,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import Grid from "../../common/components/Grid";
import PostCard from "../../common/components/PostCard";
import FetchMore from "../../common/components/FetchMore";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import { Helmet } from "react-helmet-async";

function LandingPosts() {
  const { isMobile } = useScreenWidthContext();

  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetPostsQuery({
    variables: {
      first: 6,
    },
  });

  function onEndReached(inView: boolean) {
    if (inView && !fetchingMore && data?.getPosts?.pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 6,
          after: data?.getPosts?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  if (loading || !data?.getPosts?.edges?.length) return <div />;

  return (
    <Section
      padding={
        isMobile
          ? `${theme.spacing[16]}px ${theme.spacing[24]}px`
          : `${theme.spacing[40]}px ${theme.spacing[24]}px`
      }
    >
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        gap={theme.spacing[isMobile ? 12 : 24]}
      >
        <Text typography="32B">요즘, 선물</Text>
        <Grid
          width="100%"
          gridTemplateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"}
          gridAutoRows="auto"
          gap={theme.spacing[isMobile ? 16 : 40]}
        >
          {data?.getPosts?.edges?.map((post) => (
            <PostCard key={post?.id} post={post!} />
          ))}

          <FetchMore fetchMoreRef={fetchMoreRef} />
        </Grid>
      </Flex>
    </Section>
  );
}

export default LandingPosts;
