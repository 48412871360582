import React from "react";
import Layout from "../../common/components/Layout";
import { useLocation } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import {
  PolicyType,
  useGetLatestPolicyQuery,
} from "../../../lib/apollo/graphql/generated";
import Section from "../../common/components/Section";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import dayjs from "dayjs";
import Flex from "../../common/components/Flex";

function PolicyContainer() {
  const { pathname } = useLocation();

  const isTerms = pathname === SCREENS.TERMS;

  const { loading, data } = useGetLatestPolicyQuery({
    variables: {
      type: isTerms ? PolicyType.Terms : PolicyType.Privacy,
    },
  });

  if (loading) return <div />;

  return (
    <Layout>
      <Section>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.getLatestPolicy?.body || "",
          }}
          style={{ marginBottom: theme.spacing[24] }}
        />
        <Flex flexDirection="column" gap={theme.spacing[12]}>
          <Text>
            <strong>
              공고일자 :{" "}
              {dayjs(data?.getLatestPolicy?.createdAt).format("YYYY.MM.DD")}
            </strong>
          </Text>
          <Text>
            <strong>
              시행일자 :{" "}
              {dayjs(data?.getLatestPolicy?.createdAt).format("YYYY.MM.DD")}
            </strong>
          </Text>
        </Flex>
      </Section>
    </Layout>
  );
}

export default PolicyContainer;
