export const MIN_SCREEN_WIDTH: { [key: string]: number } = {
  DESKTOP: 900,
};

export const ALT_TEXT =
  "Keeift, 키프트, 돈 버는 인맥관리, 인맥관리, 선물, 합리적 소비, 친구, 네트워크, 선물 관리, Social network, Manage friends, Human resource";

export const STORE_URL = {
  APPSTORE: "https://apps.apple.com/app/id6502627430",
  PLAYSTORE: "https://play.google.com/store/apps/details?id=com.keeift.keeift",
};
