import { ReactChildrenProp } from "global";
import React from "react";
import { ScrollRestoration } from "react-router-dom";
import TopNavigationBar from "./TopNavigationBar";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";

interface LayoutProps extends ReactChildrenProp {
  hideTopNavigationBar?: boolean;
  hideFooter?: boolean;
}

function Layout({
  children,
  hideTopNavigationBar = false,
  hideFooter = false,
}: LayoutProps) {
  return (
    <>
      <ScrollRestoration />
      {!hideTopNavigationBar && <TopNavigationBar />}
      {children}
      {!hideFooter && <Footer />}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4267748829210788"
        data-ad-slot="5974211282"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <Helmet>
        <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
      </Helmet>
    </>
  );
}

export default Layout;
