import React, { CSSProperties } from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { Link } from "react-router-dom";
import logoIcon from "../../../assets/icons/icon_logo.svg";
import logo from "../../../assets/icons/logo.svg";
import { ALT_TEXT } from "../../../utils/constants";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";

function TopNavigationBar() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="sticky"
      top={0}
      backgroundColor={theme.color.white}
      borderBottom={`1px solid ${theme.color.black}`}
      zIndex={10000000}
    >
      <nav style={styles.nav}>
        <ul
          style={{
            ...styles.ul,
            gap: theme.spacing[isMobile ? 16 : 40],
          }}
        >
          <li
            style={{
              ...styles.li,
              flex: 1,
            }}
          >
            <Link
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing[isMobile ? 16 : 40],
              }}
            >
              <img src={logoIcon} alt={ALT_TEXT} style={styles.logoIcon} />
              <img
                src={logo}
                alt={ALT_TEXT}
                style={isMobile ? styles.mobileLogo : styles.logo}
              />
            </Link>
          </li>
        </ul>
      </nav>
    </Flex>
  );
}

const styles: { [key: string]: CSSProperties } = {
  nav: {
    width: theme.appMaxWidth,
    padding: `${theme.spacing[8]}px ${theme.spacing[24]}px`,
  },
  ul: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  li: {
    display: "flex",
    alignItems: "center",
  },
  logoIcon: {
    width: 35,
    height: 33,
  },
  logo: {
    width: 130,
    height: 38,
  },
  mobileLogo: {
    width: 68,
    height: 20,
  },
};

export default TopNavigationBar;
