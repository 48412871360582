import { ReactChildrenProp } from "global";
import React from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";

interface SectionProps extends ReactChildrenProp {
  wrapperBackgroundColor?: keyof Color;
  padding?: string;
  fullWidth?: boolean;
}

function Section({
  children,
  wrapperBackgroundColor = "white",
  padding = `${theme.spacing[40]}px ${theme.spacing[24]}px`,
  fullWidth = false,
}: SectionProps) {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      backgroundColor={theme.color[wrapperBackgroundColor]}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        position="relative"
        width="100%"
        maxWidth={fullWidth ? "100%" : theme.appMaxWidth}
        flexDirection="column"
        padding={padding}
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default Section;
