import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import Text from "./Text";
import dayjs from "dayjs";

interface PostCardProps {
  post: {
    __typename?: "Post";
    id: string;
    url: string;
    title: string;
    thumbnailUri?: string | null;
    summary?: string | null;
    createdAt: any;
    postCategory: {
      __typename?: "PostCategory";
      id: string;
      names?: Array<{
        __typename?: "PostCategoryName";
        languageCode: string;
        name: string;
        id: string;
      } | null> | null;
    };
    postFrom?: {
      __typename?: "PostFrom";
      id: string;
      name: string;
      thumbnail?: { __typename?: "Media"; id: string; uri: string } | null;
    } | null;
  };
}

function PostCard({ post }: PostCardProps) {
  const {
    thumbnailUri,
    title,
    summary,
    postFrom,
    postCategory,
    createdAt,
    url,
  } = post;

  return (
    <a href={url} target="_blank">
      <Flex flexDirection="column" alignItems="center" gap={theme.spacing[12]}>
        <img
          src={thumbnailUri!}
          alt={title}
          style={{
            width: "100%",
            height: 195,
            objectFit: "cover",
            borderRadius: 40,
          }}
        />

        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex
            flex={1}
            border={`1px solid ${theme.color.black}`}
            padding={theme.spacing[4]}
            alignItems="center"
            justifyContent="center"
          >
            <Text typography="12">{postFrom?.name}</Text>
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Text typography="12">{dayjs(createdAt).format("YYYY.MM.DD")}</Text>
          </Flex>
        </Flex>

        <Text typography="20B" textAlign="center">
          {title}
        </Text>
        <Text typography="14" textAlign="center">
          {summary}
        </Text>
      </Flex>
    </a>
  );
}

export default PostCard;
