import React from "react";
import useAnalytics from "../../common/hooks/useAnalytics";
import { Outlet } from "react-router-dom";

function RootContainer() {
  useAnalytics();

  return <Outlet />;
}

export default RootContainer;
