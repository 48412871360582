import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AdCompany = {
  __typename?: 'AdCompany';
  ads?: Maybe<Array<Maybe<Advertise>>>;
  adsCount: Scalars['Int']['output'];
  ceo: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  manager?: Maybe<Scalars['String']['output']>;
  managerPhone?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AdCompanyConnection = {
  __typename?: 'AdCompanyConnection';
  edges: Array<Maybe<AdCompany>>;
  pageInfo: PageInfo;
};

export type AdCompanyInput = {
  ceo: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  managerPhone?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Advertise = {
  __typename?: 'Advertise';
  company?: Maybe<AdCompany>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type AdvertiseConnection = {
  __typename?: 'AdvertiseConnection';
  edges: Array<Maybe<Advertise>>;
  pageInfo: PageInfo;
};

export type AdvertiseInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type Announcement = {
  __typename?: 'Announcement';
  active: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  edges: Array<Maybe<Announcement>>;
  pageInfo: PageInfo;
};

export type AnnouncementInput = {
  active: Scalars['Boolean']['input'];
  body: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type Category = {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<CategoryName>>>;
  order: Scalars['Int']['output'];
};

export type CategoryInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type CategoryName = {
  __typename?: 'CategoryName';
  category: Category;
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CategoryNameInput = {
  categoryId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
};

export type Friend = {
  __typename?: 'Friend';
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Array<Maybe<FriendEmail>>>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<FriendPhone>>>;
  recordId?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Media>;
  thumbnailPath?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type FriendConnection = {
  __typename?: 'FriendConnection';
  edges: Array<Maybe<Friend>>;
  pageInfo: PageInfo;
};

export type FriendEmail = {
  __typename?: 'FriendEmail';
  email: Scalars['String']['output'];
  friend: Friend;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type FriendEmailInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type FriendInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  emailInputs?: InputMaybe<Array<InputMaybe<FriendEmailInput>>>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneInputs?: InputMaybe<Array<InputMaybe<FriendPhoneInput>>>;
  recordId?: InputMaybe<Scalars['String']['input']>;
  thumbnailInput?: InputMaybe<MediaInput>;
  thumbnailPath?: InputMaybe<Scalars['String']['input']>;
};

export type FriendPhone = {
  __typename?: 'FriendPhone';
  friend: Friend;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

export type FriendPhoneInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Gift = {
  __typename?: 'Gift';
  category?: Maybe<Category>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  friend?: Maybe<Friend>;
  id: Scalars['ID']['output'];
  isReceived: Scalars['Boolean']['output'];
  media?: Maybe<Media>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  purpose?: Maybe<Purpose>;
  receivedAt: Scalars['Date']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type GiftConnection = {
  __typename?: 'GiftConnection';
  edges: Array<Maybe<Gift>>;
  pageInfo: PageInfo;
};

export type GiftInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  friendId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isReceived: Scalars['Boolean']['input'];
  mediaInput?: InputMaybe<MediaInput>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  purposeId?: InputMaybe<Scalars['ID']['input']>;
  receivedAt: Scalars['Date']['input'];
};

export type Home = {
  __typename?: 'Home';
  amounts: HomeAmount;
  gifts: HomeGift;
  purposes: HomePurpose;
};

export type HomeAmount = {
  __typename?: 'HomeAmount';
  expense?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
};

export type HomeGift = {
  __typename?: 'HomeGift';
  expense?: Maybe<Gift>;
  income?: Maybe<Gift>;
};

export type HomePurpose = {
  __typename?: 'HomePurpose';
  expense?: Maybe<Purpose>;
  income?: Maybe<Purpose>;
};

export type Management = {
  __typename?: 'Management';
  address?: Maybe<Scalars['String']['output']>;
  ceoName?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  escroRegistration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramAddress?: Maybe<Scalars['String']['output']>;
  kakaoAddress?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  shopAddress?: Maybe<Scalars['String']['output']>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ceoName?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  escroRegistration?: InputMaybe<Scalars['String']['input']>;
  instagramAddress?: InputMaybe<Scalars['String']['input']>;
  kakaoAddress?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  shopAddress?: InputMaybe<Scalars['String']['input']>;
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type MediaInput = {
  file: Scalars['Upload']['input'];
  thumbnailFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']['output']>;
  addFriends: FriendConnection;
  addPostAsAdmin: Post;
  deleteMyFriend: Scalars['Boolean']['output'];
  deleteMyGift: Scalars['Boolean']['output'];
  deletePostAsAdmin: Scalars['Boolean']['output'];
  resign: Scalars['Boolean']['output'];
  updateAdCompanyAsAdmin: AdCompany;
  updateAdvertiseAsAdmin: Advertise;
  updateAnnouncementAsAdmin: Announcement;
  updateCategoryAsAdmin: Category;
  updateCategoryNameAsAdmin: CategoryName;
  updateManagementAsAdmin: Management;
  updateMyFriend: Friend;
  updateMyGift: Gift;
  updateMyUser: User;
  updateNoticeAsAdmin: Notice;
  updatePolicyAsAdmin: Policy;
  updatePostCategoryAsAdmin: PostCategory;
  updatePostCategoryNameAsAdmin: PostCategoryName;
  updatePostFromAsAdmin: PostFrom;
  updatePurposeAsAdmin: Purpose;
  updatePurposeNameAsAdmin: PurposeName;
};


export type MutationAddFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendInputs: Array<InputMaybe<FriendInput>>;
};


export type MutationAddPostAsAdminArgs = {
  postInput: PostInput;
};


export type MutationDeleteMyFriendArgs = {
  friendId: Scalars['ID']['input'];
};


export type MutationDeleteMyGiftArgs = {
  giftId: Scalars['ID']['input'];
};


export type MutationDeletePostAsAdminArgs = {
  postId: Scalars['ID']['input'];
};


export type MutationResignArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAdCompanyAsAdminArgs = {
  adCompanyInput: AdCompanyInput;
};


export type MutationUpdateAdvertiseAsAdminArgs = {
  advertiseInput: AdvertiseInput;
};


export type MutationUpdateAnnouncementAsAdminArgs = {
  announcementInput: AnnouncementInput;
};


export type MutationUpdateCategoryAsAdminArgs = {
  categoryInput: CategoryInput;
};


export type MutationUpdateCategoryNameAsAdminArgs = {
  categoryNameInput: CategoryNameInput;
};


export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};


export type MutationUpdateMyFriendArgs = {
  friendInput: FriendInput;
};


export type MutationUpdateMyGiftArgs = {
  giftInput: GiftInput;
};


export type MutationUpdateMyUserArgs = {
  userInput: UserInput;
};


export type MutationUpdateNoticeAsAdminArgs = {
  noticeInput: NoticeInput;
};


export type MutationUpdatePolicyAsAdminArgs = {
  policyInput: PolicyInput;
};


export type MutationUpdatePostCategoryAsAdminArgs = {
  categoryInput: PostCategoryInput;
};


export type MutationUpdatePostCategoryNameAsAdminArgs = {
  categoryNameInput: PostCategoryNameInput;
};


export type MutationUpdatePostFromAsAdminArgs = {
  postFromInput: PostFromInput;
};


export type MutationUpdatePurposeAsAdminArgs = {
  purposeInput: PurposeInput;
};


export type MutationUpdatePurposeNameAsAdminArgs = {
  purposeNameInput: PurposeNameInput;
};

export type Notice = {
  __typename?: 'Notice';
  active: Scalars['Boolean']['output'];
  announcement?: Maybe<Announcement>;
  createdAt: Scalars['DateTime']['output'];
  externalUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NoticeConnection = {
  __typename?: 'NoticeConnection';
  edges: Array<Maybe<Notice>>;
  pageInfo: PageInfo;
};

export type NoticeInput = {
  active: Scalars['Boolean']['input'];
  announcementId?: InputMaybe<Scalars['ID']['input']>;
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
};

export type Policy = {
  __typename?: 'Policy';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: PolicyType;
};

export type PolicyInput = {
  body: Scalars['String']['input'];
  type: PolicyType;
};

export enum PolicyType {
  Privacy = 'PRIVACY',
  Terms = 'TERMS'
}

export type Post = {
  __typename?: 'Post';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  postCategory: PostCategory;
  postFrom?: Maybe<PostFrom>;
  summary?: Maybe<Scalars['String']['output']>;
  thumbnailUri?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PostCategory = {
  __typename?: 'PostCategory';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<PostCategoryName>>>;
  order: Scalars['Int']['output'];
};

export type PostCategoryInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type PostCategoryName = {
  __typename?: 'PostCategoryName';
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postCategory: PostCategory;
};

export type PostCategoryNameInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postCategoryId: Scalars['ID']['input'];
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<Maybe<Post>>;
  pageInfo: PageInfo;
};

export type PostFrom = {
  __typename?: 'PostFrom';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Media>;
};

export type PostFromInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnailInput?: InputMaybe<MediaInput>;
};

export type PostInput = {
  postCategoryId: Scalars['ID']['input'];
  postFromId?: InputMaybe<Scalars['ID']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type Purpose = {
  __typename?: 'Purpose';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<PurposeName>>>;
  order: Scalars['Int']['output'];
};

export type PurposeInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type PurposeName = {
  __typename?: 'PurposeName';
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  purpose: Purpose;
};

export type PurposeNameInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  purposeId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']['output']>;
  checkIfEmailExist: Scalars['Boolean']['output'];
  getActiveAdvertise: Array<Maybe<Advertise>>;
  getActiveNotices: Array<Maybe<Notice>>;
  getAdCompanies: AdCompanyConnection;
  getAdCompany: AdCompany;
  getAdvertise: Advertise;
  getAllAdvertisesAsAdmin: AdvertiseConnection;
  getAllNoticesAsAdmin: NoticeConnection;
  getAnnouncement: Announcement;
  getAnnouncements: AnnouncementConnection;
  getCategories: Array<Maybe<Category>>;
  getFriend?: Maybe<Friend>;
  getGift: Gift;
  getHome: Home;
  getLatestPolicy: Policy;
  /** This query is for client application and admin both! */
  getManagement?: Maybe<Management>;
  getMyFriends: FriendConnection;
  getMyGifts: GiftConnection;
  getMyGiftsByDate: Array<Maybe<Gift>>;
  getMyUser?: Maybe<User>;
  getNotice: Notice;
  /**
   * This is used if you wanna show or see policy records by type
   * For now, it can be used in the admin console, but later you can use this in the client app
   */
  getPolicies: Array<Maybe<Policy>>;
  getPostCategories: Array<Maybe<PostCategory>>;
  getPostFrom: PostFrom;
  getPostFroms: Array<Maybe<PostFrom>>;
  getPosts: PostConnection;
  getPurposes: Array<Maybe<Purpose>>;
  getUser: User;
  getUserFriendsAsAdmin: FriendConnection;
  getUserGiftsAsAdmin: GiftConnection;
  getUserGiftsByDateAsAdmin: Array<Maybe<Gift>>;
  getUsersAsAdmin: UserConnection;
  searchAdCompanies: AdCompanyConnection;
};


export type QueryCheckIfEmailExistArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetAdCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdCompanyArgs = {
  adCompanyId: Scalars['ID']['input'];
};


export type QueryGetAdvertiseArgs = {
  advertiseId: Scalars['ID']['input'];
};


export type QueryGetAllAdvertisesAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllNoticesAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementsArgs = {
  activeOnly: Scalars['Boolean']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetFriendArgs = {
  friendId: Scalars['ID']['input'];
};


export type QueryGetGiftArgs = {
  giftId: Scalars['ID']['input'];
};


export type QueryGetHomeArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QueryGetLatestPolicyArgs = {
  type: PolicyType;
};


export type QueryGetMyFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMyGiftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMyGiftsByDateArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QueryGetNoticeArgs = {
  noticeId: Scalars['ID']['input'];
};


export type QueryGetPoliciesArgs = {
  type: PolicyType;
};


export type QueryGetPostFromArgs = {
  postFromId: Scalars['ID']['input'];
};


export type QueryGetPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  postCategoryId?: InputMaybe<Scalars['ID']['input']>;
  postFromId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetUserFriendsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUserGiftsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUserGiftsByDateAsAdminArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchAdCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Media>;
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  friends?: Maybe<Array<Maybe<Friend>>>;
  friendsCount: Scalars['Int']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  resignReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCode?: InputMaybe<Scalars['String']['input']>;
};

export type GetActiveAdvertiseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveAdvertiseQuery = { __typename?: 'Query', getActiveAdvertise: Array<{ __typename?: 'Advertise', id: string, imageUrl: string, url: string, title?: string | null, description?: string | null, from?: any | null, to?: any | null, createdAt: any, updatedAt: any, company?: { __typename?: 'AdCompany', id: string, name: string, url?: string | null } | null } | null> };

export type GetAnnouncementsQueryVariables = Exact<{
  activeOnly: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAnnouncementsQuery = { __typename?: 'Query', getAnnouncements: { __typename?: 'AnnouncementConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Announcement', id: string, title: string, body: string, active: boolean, createdAt: any, updatedAt: any } | null> } };

export type GetManagementQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManagementQuery = { __typename?: 'Query', getManagement?: { __typename?: 'Management', id: string, companyName?: string | null, address?: string | null, copyright?: string | null, email?: string | null, ceoName?: string | null, registrationNumber?: string | null, escroRegistration?: string | null, phone?: string | null, kakaoAddress?: string | null, shopAddress?: string | null, instagramAddress?: string | null } | null };

export type GetLatestPolicyQueryVariables = Exact<{
  type: PolicyType;
}>;


export type GetLatestPolicyQuery = { __typename?: 'Query', getLatestPolicy: { __typename?: 'Policy', id: string, type: PolicyType, body: string, createdAt: any } };

export type GetPostsQueryVariables = Exact<{
  postCategoryId?: InputMaybe<Scalars['ID']['input']>;
  postFromId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPostsQuery = { __typename?: 'Query', getPosts: { __typename?: 'PostConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Post', id: string, url: string, title: string, thumbnailUri?: string | null, summary?: string | null, createdAt: any, postCategory: { __typename?: 'PostCategory', id: string, names?: Array<{ __typename?: 'PostCategoryName', languageCode: string, name: string, id: string } | null> | null }, postFrom?: { __typename?: 'PostFrom', id: string, name: string, thumbnail?: { __typename?: 'Media', id: string, uri: string } | null } | null } | null> } };


export const GetActiveAdvertiseDocument = gql`
    query GetActiveAdvertise {
  getActiveAdvertise {
    id
    company {
      id
      name
      url
    }
    imageUrl
    url
    title
    description
    from
    to
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetActiveAdvertiseQuery__
 *
 * To run a query within a React component, call `useGetActiveAdvertiseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAdvertiseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAdvertiseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveAdvertiseQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>(GetActiveAdvertiseDocument, options);
      }
export function useGetActiveAdvertiseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>(GetActiveAdvertiseDocument, options);
        }
export function useGetActiveAdvertiseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>(GetActiveAdvertiseDocument, options);
        }
export type GetActiveAdvertiseQueryHookResult = ReturnType<typeof useGetActiveAdvertiseQuery>;
export type GetActiveAdvertiseLazyQueryHookResult = ReturnType<typeof useGetActiveAdvertiseLazyQuery>;
export type GetActiveAdvertiseSuspenseQueryHookResult = ReturnType<typeof useGetActiveAdvertiseSuspenseQuery>;
export type GetActiveAdvertiseQueryResult = Apollo.QueryResult<GetActiveAdvertiseQuery, GetActiveAdvertiseQueryVariables>;
export const GetAnnouncementsDocument = gql`
    query GetAnnouncements($activeOnly: Boolean!, $first: Int, $after: String) {
  getAnnouncements(activeOnly: $activeOnly, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      title
      body
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *      activeOnly: // value for 'activeOnly'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAnnouncementsQuery(baseOptions: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables> & ({ variables: GetAnnouncementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
      }
export function useGetAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
        }
export function useGetAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
        }
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useGetAnnouncementsSuspenseQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>;
export const GetManagementDocument = gql`
    query GetManagement {
  getManagement {
    id
    companyName
    address
    copyright
    email
    ceoName
    registrationNumber
    escroRegistration
    phone
    kakaoAddress
    shopAddress
    instagramAddress
  }
}
    `;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(baseOptions?: Apollo.QueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
      }
export function useGetManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
        }
export function useGetManagementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
        }
export type GetManagementQueryHookResult = ReturnType<typeof useGetManagementQuery>;
export type GetManagementLazyQueryHookResult = ReturnType<typeof useGetManagementLazyQuery>;
export type GetManagementSuspenseQueryHookResult = ReturnType<typeof useGetManagementSuspenseQuery>;
export type GetManagementQueryResult = Apollo.QueryResult<GetManagementQuery, GetManagementQueryVariables>;
export const GetLatestPolicyDocument = gql`
    query GetLatestPolicy($type: PolicyType!) {
  getLatestPolicy(type: $type) {
    id
    type
    body
    createdAt
  }
}
    `;

/**
 * __useGetLatestPolicyQuery__
 *
 * To run a query within a React component, call `useGetLatestPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPolicyQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestPolicyQuery(baseOptions: Apollo.QueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables> & ({ variables: GetLatestPolicyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
      }
export function useGetLatestPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
        }
export function useGetLatestPolicySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
        }
export type GetLatestPolicyQueryHookResult = ReturnType<typeof useGetLatestPolicyQuery>;
export type GetLatestPolicyLazyQueryHookResult = ReturnType<typeof useGetLatestPolicyLazyQuery>;
export type GetLatestPolicySuspenseQueryHookResult = ReturnType<typeof useGetLatestPolicySuspenseQuery>;
export type GetLatestPolicyQueryResult = Apollo.QueryResult<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>;
export const GetPostsDocument = gql`
    query GetPosts($postCategoryId: ID, $postFromId: ID, $first: Int, $after: String) {
  getPosts(
    postCategoryId: $postCategoryId
    postFromId: $postFromId
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      postCategory {
        id
        names {
          languageCode
          name
          id
        }
      }
      postFrom {
        id
        name
        thumbnail {
          id
          uri
        }
      }
      url
      title
      thumbnailUri
      summary
      createdAt
    }
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      postCategoryId: // value for 'postCategoryId'
 *      postFromId: // value for 'postFromId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export function useGetPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsSuspenseQueryHookResult = ReturnType<typeof useGetPostsSuspenseQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;