import { InMemoryCache } from "@apollo/client";
import customRelayStylePagination from "./customRelayStylePagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getPosts: customRelayStylePagination(["postCategoryId", "postFromId"]),
      },
    },
  },
});

export const setDefaultCache = (cache: any) => {
  // console.info("set default cache:", cache);
};

setDefaultCache(cache);

export default cache;
