import React, { CSSProperties } from "react";
import Section from "../../common/components/Section";
import introImage from "../../../assets/images/intro_image.png";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import Button from "../../common/components/Button";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import {
  deviceDetect,
  getUA,
  isIOS,
  isMacOs,
  osName,
} from "react-device-detect";
import { STORE_URL } from "../../../utils/constants";

function LandingIntro() {
  const { isMobile } = useScreenWidthContext();

  const isApple = isIOS || isMacOs;

  const downloadUrl = isApple ? STORE_URL.APPSTORE : STORE_URL.PLAYSTORE;

  function onDownloadClick() {
    try {
      const userAgent = getUA;
      const deviceInfo = deviceDetect(userAgent);

      if (!!(window as any)?.gtag) {
        const { gtag } = window as any;

        gtag("event", `landing_top_download`, {
          ...deviceInfo,
        });
      }
    } catch (err: any) {}
  }

  return isMobile ? (
    <Section wrapperBackgroundColor="primary2">
      <Flex flexDirection="column" gap={theme.spacing[16]} width="100%">
        <Text typography="32B">
          {`돈 버는 인맥관리\n 더 나은 내가 되기 위한\n합리적 소비`}
        </Text>
        <Text typography="16" marginBottom={theme.spacing[32]}>
          키프트에 기록하는 것만으로도 더 나은 나로 성장해요
        </Text>
        <div style={styles.mobileEllipse}>
          <img src={introImage} style={styles.mobileImage} />
        </div>
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop={theme.spacing[16]}
        >
          <a href={downloadUrl} target="_blank">
            <Button
              fullWidth
              size="md"
              text="다운로드 받기"
              onClick={onDownloadClick}
            />
          </a>
        </Flex>
      </Flex>
    </Section>
  ) : (
    <Section wrapperBackgroundColor="primary2">
      <Flex alignItems="flex-start" width="100%">
        <Flex
          flexDirection="column"
          alignItems="center"
          flex={1}
          padding={`${theme.spacing[52]}px ${theme.spacing[8]}px`}
          gap={theme.spacing[52]}
        >
          <Text typography="40B" textAlign="center">
            {`돈 버는 인맥관리\n 더 나은 내가 되기 위한 합리적 소비`}
          </Text>
          <Text typography="24">
            키프트에 기록하는 것만으로도 더 나은 나로 성장해요
          </Text>
          <a href={downloadUrl} target="_blank">
            <Button
              width={358}
              size="md"
              text="다운로드 받기"
              onClick={onDownloadClick}
            />
          </a>
        </Flex>

        <Flex flex={0.8} alignItems="center" justifyContent="center">
          <div style={styles.ellipse}>
            <img src={introImage} style={styles.image} />
          </div>
        </Flex>
      </Flex>
    </Section>
  );
}

const styles: { [key: string]: CSSProperties } = {
  ellipse: {
    position: "relative",
    width: "35vw",
    height: "35vw",
    maxWidth: 600,
    maxHeight: 600,
    borderRadius: 300,
    background: "linear-gradient(180deg, #8ACBBE 0%, #D4D4D4 100%)",
  },
  mobileEllipse: {
    position: "relative",
    width: "50vw",
    height: "50vw",
    maxWidth: 180,
    maxHeight: 180,
    borderRadius: 90,
    background: "linear-gradient(180deg, #8ACBBE 0%, #D4D4D4 100%)",
    margin: "0px auto",
  },
  image: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "-13%",
    width: "90%",
    objectFit: "contain",
  },
  mobileImage: {
    position: "absolute",
    bottom: 0,
    right: "-35%",
    width: "120%",
    objectFit: "contain",
  },
};

export default LandingIntro;
