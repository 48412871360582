import React from "react";
import Section from "./Section";
import { useGetManagementQuery } from "../../../lib/apollo/graphql/generated";
import iconLogo from "../../../assets/icons/icon_logo.svg";
import logo from "../../../assets/icons/logo.svg";
import arrowIcon from "../../../assets/icons/footer_arrow.svg";
import playstore from "../../../assets/images/playstore_download.png";
import appstore from "../../../assets/images/appstore_download.png";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { ALT_TEXT, STORE_URL } from "../../../utils/constants";
import Text from "./Text";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";
import { deviceDetect, getUA } from "react-device-detect";

function Footer() {
  const { isMobile } = useScreenWidthContext();

  const { loading, data } = useGetManagementQuery();

  function onDownloadClick(type: string) {
    try {
      const userAgent = getUA;
      const deviceInfo = deviceDetect(userAgent);

      if (!!(window as any)?.gtag) {
        const { gtag } = window as any;

        gtag("event", `footer_download_${type}`, {
          ...deviceInfo,
        });
      }
    } catch (err: any) {}
  }

  if (loading) return <div />;

  return (
    <footer>
      <Section wrapperBackgroundColor="primary">
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          padding={`${theme.spacing[isMobile ? 8 : 16]}px ${
            theme.spacing[isMobile ? 4 : 16]
          }px`}
          gap={theme.spacing[isMobile ? 8 : 24]}
          borderBottom={`2px solid ${theme.color.black}`}
          marginBottom={theme.spacing[isMobile ? 8 : 16]}
        >
          <img
            src={iconLogo}
            alt={ALT_TEXT}
            style={{ width: 35, height: 33 }}
          />
          <Text typography={isMobile ? "20B" : "32B"}>DOWNLOAD</Text>
          <img
            src={arrowIcon}
            alt={ALT_TEXT}
            style={{ width: isMobile ? 30 : 46, height: isMobile ? 17 : 27 }}
          />
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
            justifyContent="center"
            gap={theme.spacing[8]}
          >
            <a
              href={STORE_URL.APPSTORE}
              target="_blank"
              onClick={() => onDownloadClick("appstore")}
            >
              <img
                src={appstore}
                style={{ height: isMobile ? 37 : 76, objectFit: "contain" }}
              />
            </a>
            <a
              href={STORE_URL.PLAYSTORE}
              target="_blank"
              onClick={() => onDownloadClick("playstore")}
            >
              <img
                src={playstore}
                style={{ height: isMobile ? 39 : 78, objectFit: "contain" }}
              />
            </a>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[8]}
          marginBottom={theme.spacing[16]}
        >
          <img src={logo} style={{ width: 68, height: 20 }} />

          <Link to={SCREENS.TERMS}>
            <Text typography={isMobile ? "12" : "14"}>· 이용약관</Text>
          </Link>

          <Link to={SCREENS.PRIVACY}>
            <Text typography={isMobile ? "12" : "14"}>· 개인정보처리방침</Text>
          </Link>

          <Link to={`mailto:${data?.getManagement?.email}`}>
            <Text typography={isMobile ? "12" : "14"}>· 제휴문의</Text>
          </Link>
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Text typography={isMobile ? "12" : "14"}>
            {data?.getManagement?.companyName} | 사업자등록번호 :{" "}
            {data?.getManagement?.registrationNumber} |{" "}
            {data?.getManagement?.address}
          </Text>
        </Flex>
      </Section>
    </footer>
  );
}

export default Footer;
