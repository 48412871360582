import { createBrowserRouter } from "react-router-dom";
import LandingContainer from "../../../modules/landing/container/LandingContainer";
import PolicyContainer from "../../../modules/policy/container/PolicyContainer";
import RootContainer from "../../../modules/root/container/RootContainer";

export const SCREENS = {
  LANDING: "/",

  ANNOUNCEMENT: "/announcement",

  TERMS: "/terms",
  PRIVACY: "/privacy",
};

export const router = createBrowserRouter([
  {
    element: <RootContainer />,
    children: [
      {
        path: SCREENS.LANDING,
        element: <LandingContainer />,
      },

      {
        path: SCREENS.TERMS,
        element: <PolicyContainer />,
      },

      {
        path: SCREENS.PRIVACY,
        element: <PolicyContainer />,
      },
    ],
  },
]);
