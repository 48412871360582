import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (!!(window as any)?.gtag) {
      (window as any).gtag("event", "page_view", {
        page_path: location.pathname,
      });
    }
  }, [location]);
}

export default useAnalytics;
